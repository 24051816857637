<template>
	<div class="layout">
		<MainHeader></MainHeader>
		<router-view/>
		<MainFooter></MainFooter>
	</div>
</template>

<script>
	import MainHeader from "@/components/header.vue"
	import MainFooter from "@/components/footer.vue"
	export default {
		name: 'Home',
		components: {
			MainHeader,
			MainFooter
		}
	}
</script>
<style lang="scss">
	//scss
</style>
