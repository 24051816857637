<template>
	<div class="main-header">
		<div class="left">
			<!-- logo -->
			<router-link to="/layout">
				<div class="logo">
					<el-image class="logo-img" :src="require('@/assets/img/logo.png')" fit="contain"></el-image>
					<span>智能物流管控平台</span>
				</div>
			</router-link>
			<!-- 导航 -->
			<el-menu class="header-el-menu" mode="horizontal" :default-active="$route.path" router>
				<el-menu-item index="/homePage">首页</el-menu-item>
				<el-menu-item index="/logisticsCost">第三方物流费用</el-menu-item>
				<el-menu-item index="/logisticsCompany">物流公司</el-menu-item>
				<el-menu-item index="/carInfo">车辆信息</el-menu-item>
				<el-menu-item index="/consignor">发货员</el-menu-item>
				<el-menu-item v-if="common.statistic() || common.canAudit()" index="/statistics">物流统计</el-menu-item>
				<el-menu-item v-if="common.statistic()" index="/followUp">回访</el-menu-item>
			</el-menu>
		</div>
		<div class="right">
			<!-- 下拉菜单 -->
			<el-dropdown v-if="name" @command="handleCommand">
				<span class="el-dropdown-link">
					你好，{{name}}!<i class="el-icon-arrow-down el-icon--right"></i>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item command="3">宝达系统使用说明</el-dropdown-item>
					<el-dropdown-item command="2">玉韦耶克系统使用说明</el-dropdown-item>
					<el-dropdown-item command="1">修改密码</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
			<!-- 退出按钮 -->
			<el-image v-if="name" @click="logOut" class="sign-out-btn" :src="require('@/assets/img/sign-out.png')"
				fit="contain"></el-image>
			<!-- 登录按钮 -->
			<el-button v-if="!name" class="login-btn" plain round @click="loginClick">登录</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				name: '',
			}
		},
		created() {
			this.name = localStorage.getItem('name')
			//判断是否登陆
			let token = localStorage.getItem('token')
			if (!token) {
				this.loginClick()
			}
			let isFirstLogin = localStorage.getItem('isFirstLogin')
			if(isFirstLogin == "true"){
				this.$message({
					type: "error",
					message: "密码过于简单，请修改密码!",
				});
				this.$router.push("/changePassword")
			}
		},
		methods: {
			handleCommand(command) {
				if(command==='1'){
					this.$router.push("/changePassword")
				}else if(command==='2'){
					this.$router.push("/ywyeInstructions")
				}else if(command==='3'){
					this.$router.push("/instructions")
				}
			},
			loginClick() {
				this.$router.push("/")
			},
			logOut() {
				//elementui 确认框
				this.$confirm('确认退出吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					localStorage.clear()
					this.name = ''
					this.$router.push("/")
				})
			}
		}
	}
</script>

<style>
</style>
